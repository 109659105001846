<template>
  <el-row :gutter="20">
    <OrgTable />
  </el-row>
</template>
<script>
import OrgTable from './components/orgtable.vue'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { OrgTable },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      member: 'auth/member',
      token: 'auth/token',
      organisations: 'organisations/organisations',
    }),
    ...mapFields('devices', ['filter']),
  },
  created() {
  },
  mounted() {
    let payload = {
      params: {
        ...this.filter,
      },
    }
    this.getOrganisations(payload)
  },
  methods: {
    ...mapActions({
      getOrganisations: 'organisations/getOrganisations',
    }),
  },
}
</script>
