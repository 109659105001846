<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Ajouter organisation"
    placement="top"
  >
    <el-button
      type="primary"
      :icon="PlusIcon"
      plain
      @click="dialogFormAdd = true"
      >Ajouter</el-button
    >
  </el-tooltip>
  <el-dialog
    title="Ajout d'une organisation"
    v-model="dialogFormAdd"
    append-to-body
    center
  >
    <el-form ref="orgForm" :model="form" :rules="rules">
      <el-form-item label="Nom :" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Région" :label-width="formLabelWidth" prop="region">
        <el-select v-model="form.region" class="m-2" placeholder="Région">
          <el-option
            v-for="item in REGION_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Timezone"
        :label-width="formLabelWidth"
        prop="timezone"
      >
        <el-select v-model="form.timezone" class="m-2" placeholder="Timezone">
          <el-option
            v-for="item in TIMEZONE_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Devise"
        :label-width="formLabelWidth"
        prop="currency"
      >
        <el-select v-model="form.currency" class="m-2" placeholder="Devise">
          <el-option
            v-for="item in CURRENCY_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Coordonnées" :label-width="formLabelWidth">
        <el-col :span="12">
          <el-input
            v-model="form.latitude"
            autocomplete="off"
            placeholder="Latitude"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model="form.longitude"
            autocomplete="off"
            placeholder="Longitude"
          ></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormAdd = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button type="primary" @click="ajoutorg('orgForm')"
          >Ajouter</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { timezone } from '../../../utils/timezone'
import {
  REGION_OPTIONS,
  TIMEZONE_OPTIONS,
  CURRENCY_OPTIONS,
} from '../../../helpers/StaticData'
export default {
  data() {
    return {
      REGION_OPTIONS,
      TIMEZONE_OPTIONS,
      CURRENCY_OPTIONS,
      dialogFormAdd: false,
      formLabelWidth: '150px',
      timezone: timezone,
      form: {
        name: '',
        region: '',
        timezone: '',
        statut: '',
        zonedetemps: '',
        longitude: '',
        latitude: '',
        currency: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
        region: [
          {
            required: true,
            message: 'Veuillez saisir le région',
            trigger: 'change',
          },
        ],
        timezone: [
          {
            required: true,
            message: 'Veuillez saisir la timezone',
            trigger: 'change',
          },
        ],
        currency: [
          {
            required: true,
            message: 'Veuillez saisir le devise',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      organisations: 'organisations/organisations',
    }),
  },
  methods: {
    ...mapActions({
      addorg: 'organisations/addorg',
    }),
    ajoutorg(orgForm) {
      this.$refs[orgForm].validate((valid) => {
        if (valid) {
          let payload = {
            data: {
              name: this.form.name,
              timezone: this.form.timezone,
              region: this.form.region,
              currency: this.form.currency,
              attributes: {
                latitude: this.form.latitude,
                longitude: this.form.longitude,
              },
            },
          }

          this.addorg(payload)
            .then((response) => {
              this.$store.commit('organisations/ADD_ORGANISATION', response)
              this.dialogFormAdd = false
              this.reset()
            })
            .catch((error) => console.log(error))
        } else {
          return false
        }
      })
    },
    reset() {
      this.form.name = null
      this.form.region = null
      this.form.timezone = null
      this.form.currency = null
      this.form.latitude = null
      this.form.longitude = null
    },
  },
}
</script>
