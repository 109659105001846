export const REGION_OPTIONS = [
    { value: 'europe_france', label: 'europe_france' },
    { value: 'africa_tunisia', label: 'africa_tunisia' },
]
export const TIMEZONE_OPTIONS = [
    { value: 'Europe/Paris', label: 'Europe/Paris' },
    { value: 'Africa/Tunis', label: 'Africa/Tunis' },
]
export const CURRENCY_OPTIONS = [
    { value: 'EUR', label: 'EUR' },
    { value: 'TND', label: 'TND' },
]
