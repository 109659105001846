<template>
  <el-container direction="vertical">
    <el-row justify="space-between">
      <el-col :span="4"
        >
        <div class="organisation-title">
        <span>Organisations </span
        ><span class="organisations-count"  v-if="totalElements > 0">{{ totalElements }}</span>
        </div>
        </el-col
      >
      <el-col :span="6">
        <el-space alignment="flex-end">
          <el-input
            v-model="filter.q"
            :prefix-icon="SearchIcon"
            placeholder="Taper pour chercher"
            @keyup.enter="handleSearch"
          />
          <add-org />
        </el-space>
      </el-col>
    </el-row>
    <el-table stripe :data="organisations" style="width: 100%">
      <el-table-column label="Nom" prop="name"> </el-table-column>
      <el-table-column label="Slug" prop="slug"></el-table-column>
      <el-table-column label="Région" prop="region"></el-table-column>
      <el-table-column label="devise" prop="currency"></el-table-column>
      <el-table-column v-if="isAppUser" label="Revendeur" prop="retailer">
        <template #default="scope"
          >{{ formatRetailer(scope.row.retailer) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Action" width="180">
        <template #default="scope" align="right">
          <el-space>
            <el-tooltip placement="left">
              <template #content> Changer état</template>
              <el-switch
                v-model="scope.row.enabled"
                :disabled="!isAppAdmin"
                @change="enableOrganization(scope.row)"
              />
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Membres"
              placement="top"
            >
              <el-button
                type="primary"
                plain
                size="small"
                :icon="UserIcon"
                @click="handleMembers(scope.row)"
              ></el-button>
            </el-tooltip>
            <edit-org :index="scope.$index" :row="scope.row" />
            <el-popconfirm
              confirmButtonText="Oui"
              cancelButtonText="Annuler"
              @confirm="handleDelete(scope.$index, scope.row)"
              :icon="InfoIcon"
              iconColor="#F56C6C"
              title="Voulez vous vraiment supprimer cette organisation ?"
            >
              <template #reference>
                <el-button
                  size="small"
                  type="danger"
                  :icon="DeleteIcon"
                ></el-button>
              </template>
            </el-popconfirm>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="row-bg" justify="center" v-if="metadata">
      <el-pagination
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="metadata.totalElements"
        :page-size="20"
        @current-change="setPage"
      >
      </el-pagination>
    </el-row>
  </el-container>
</template>

<script>
import EditOrg from './editorg.vue'
import AddOrg from './addorg.vue'
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import _ from 'lodash'

export default {
  components: { EditOrg, AddOrg },
  data() {
    return {
      pageSize: 20,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAppUser: 'auth/isAppUser',
      isAppAdmin: 'auth/isAppAdmin',
      organisations: 'organisations/organisations',
      metadata: 'organisations/metadata',
    }),
    ...mapFields('devices', ['filter']),
    page() {
      return Number(_.get(this.metadata, 'pageable.pageNumber', 0)) + 1
    },
    totalElements() {
      return _.get(this.metadata, 'totalElements', '0')
    },
  },
  methods: {
    ...mapActions({
      setOrg: 'organisations/setOrg',
      deleteOrg: 'organisations/deleteOrg',

      getOrganisations: 'organisations/getOrganisations',
    }),
    formatRetailer(retailer) {
      if (retailer) return retailer.name
    },
    handleMembers(row) {
      this.$router.push({
        name: 'OrgMembers',
        params: { id: row.id , name: row.name },
      })
    },
    handleSearch() {
      let payload = {
        params: {
          ...this.filter,
        },
      }
      this.getOrganisations(payload)
    },
    enableOrganization(row) {
      let payload = {
        id: row.id,
        data: {
          enabled: row.enabled,
        },
      }
      this.$store.dispatch('organisations/updateOrganisation', payload)
    },
    handleDelete(index, row) {
      this.deleteOrg({
        id: row.id,
      })
        .then(() => {
          this.handleSearch()
        })
        .catch((error) => {
          console.log('delete error : ', error)
        })
    },
    setPage(val) {
      this.filter.page = val - 1
      let payload = {
        params: {
          ...this.filter,
        },
      }

      this.getOrganisations(payload)
    },
  },
}
</script>

<style>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}
.button-yoda-user:hover {
  background-color: #c19ee0;
  border: white;
  color: white;
}
form label {
  font-weight: bold;
}
.organisation-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.organisation-title span:first-child {
  margin-right: 5px;
}

.organisations-count {
  font-size: 16px;
  font-weight: normal;
  margin-left: 5px;
}

</style>
