<template>
  <el-tooltip class="item" effect="dark" content="Modifier" placement="top">
    <el-button
      :icon="EditIcon"
      @click="handleEdit(index, row)"
      size="small"
    ></el-button>
  </el-tooltip>
  <el-dialog
    title="Modification d'une organisation"
    v-model="dialogFormEdit"
    append-to-body
    center
  >
    <el-form :model="form">
      <el-form-item label="Nom :" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Région" :label-width="formLabelWidth">
        <el-select v-model="form.region" class="m-2" placeholder="Région">
          <el-option
            v-for="item in REGION_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Timezone" :label-width="formLabelWidth">
        <el-select v-model="form.timezone" class="m-2" placeholder="Timezone">
          <el-option
            v-for="item in TIMEZONE_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Devise" :label-width="formLabelWidth">
        <el-select v-model="form.currency" class="m-2" placeholder="Timezone">
          <el-option
            v-for="item in CURRENCY_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Coordonnées" :label-width="formLabelWidth">
        <el-col :span="12">
          <el-input
            v-model="form.latitude"
            autocomplete="off"
            placeholder="Latitude"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model="form.longitude"
            autocomplete="off"
            placeholder="Longitude"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        v-if="isAppAdmin"
        label="Revendeur "
        :label-width="formLabelWidth"
        prop="retailerId"
      >
        <el-select
          v-model="form.retailerId"
          placeholder="Revendeur"
          clearable
          filterable
          :loading="loading"
        >
          <el-option
            v-for="item in retailerOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormEdit = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button type="primary" @click.stop.prevent="update()"
          >Confirmer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import {
  REGION_OPTIONS,
  TIMEZONE_OPTIONS,
  CURRENCY_OPTIONS,
} from '../../../helpers/StaticData'
export default {
  props: ['index', 'row'],
  data() {
    return {
      REGION_OPTIONS,
      TIMEZONE_OPTIONS,
      CURRENCY_OPTIONS,
      dialogFormEdit: false,
      radio: 3,
      formLabelWidth: '150px',
      form: {
        name: '',
        region: '',
        timezone: '',
        enabled: '',
        longitude: '',
        latitude: '',
        currency: '',
        retailerId: '',
      },
      retailerOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAppAdmin: 'auth/isAppAdmin',
      organisations: 'organisations/organisations',
      org: 'organisations/organisation',
      retailers: 'retailers/retailers',
    }),
    ...mapState('organisations', ['loading']),
  },
  watch: {
    'form.retailerId': {
      handler(newVal) {
        if (newVal) this.getRetailers()
      },
    },
  },
  methods: {
    ...mapActions({
      setOrg: 'organisations/setOrg',
      updateOrganisation: 'organisations/updateOrganisation',
    }),
    update() {
      let payload = {
        id: this.org.id,
        data: {
          name: this.form.name,
          timezone: this.form.timezone,
          region: this.form.region,
          currency: this.form.currency,
          retailerId: this.form.retailerId,
          attributes: {
            latitude: this.form.latitude,
            longitude: this.form.longitude,
          },
        },
      }
      this.updateOrganisation(payload)
        .then((response) => {
          this.$store.commit('organisations/ASSIGN_ORGANISATION', response)
          this.dialogFormEdit = false
        })
        .catch((error) => console.log(error))
    },
    handleEdit(index, row) {
      this.dialogFormEdit = true
      this.populate(row)
      this.setOrg(row)
    },
    populate(row) {
      this.form.name = row.name || null
      this.form.region = row.region || null
      this.form.timezone = row.timezone || null
      this.form.currency = row.currency || null
      this.form.latitude = row.attributes.latitude
      this.form.longitude = row.attributes.longitude
      this.form.retailerId = row.retailerId
    },
    getRetailers() {
      if (!this.isAppAdmin) return
      this.loading = true
      if (this.retailers.length > 0) {
        this.retailerOptions = this.retailers
        this.loading = false
        return
      }
      this.$store
        .dispatch('retailers/getRetailers', {})
        .then(() => {
          this.retailerOptions = this.retailers
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
